import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "../../../components/custom-widgets/icon";
import LoanOfficerSearch from "../../../components/loan-officers/loan-officer-search";
import Button from "../../../components/custom-widgets/button";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";

const UsingHomeEquityHelocVsHomeEquityLoan = () => {
  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/using-home-equity-heloc-vs-home-equity-loan/hero-home-equity-111423-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/using-home-equity-heloc-vs-home-equity-loan/hero-home-equity-111423-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/using-home-equity-heloc-vs-home-equity-loan/hero-home-equity-111423-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/using-home-equity-heloc-vs-home-equity-loan/hero-home-equity-111423-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/using-home-equity-heloc-vs-home-equity-loan/hero-home-equity-111423-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/using-home-equity-heloc-vs-home-equity-loan/hero-home-equity-111423-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/personal-banking/home-loans/using-home-equity-heloc-vs-home-equity-loan/hero-home-equity-111423-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
      card01: file(relativePath: { eq: "cards/thumbnail-heloc-to-customize-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      card02: file(relativePath: { eq: "cards/thumbnail-qualify-for-heloc-04182023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      card03: file(relativePath: { eq: "cards/thumbnail-heloc-student-loans-071223.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      card04: file(relativePath: { eq: "cards/thumbnail-5-green-living-tips-for-your-home-091423.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const title = "How to Use Home Equity with a HELOC or a HELOAN";
  const description =
    "Find out how to pull equity out of your home with a HELOC (home equity line of credit) or HELOAN (home equity loan). Find the right loan for you at WaFd Bank.";

  const seoData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/using-home-equity-heloc-vs-home-equity-loan"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-home-equity-111423.jpg"
      }
    ]
  };

  const [showLOModal, setShowLOModal] = useState(false);
  const handleCloseLOModal = () => setShowLOModal(false);
  const handleShowLOModal = () => {
    setShowLOModal(true);
  };

  const heroChevronData = {
    id: "using-home-equity-heloc-vs-home-equity-loan-hero",
    ...getHeroImgVariables(pageData),
    altText: "Smiling young couple sitting together on their kitchen floor.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Put Your Home's Equity to Good Use"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            text: "Contact a Loan Officer",
            url: "/personal-banking/contact-loan-officer",
            class: "btn-white",
            type: "link"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Home Equity Loan"
    }
  ];

  const benefitsOfDiscountRateOnHELOC = [
    {
      id: 1,
      title: "Renovate your home sweet home"
    },
    {
      id: 2,
      title: "Pay off high interest bills"
    },
    {
      id: 3,
      title: "Invest in education"
    },
    {
      id: 4,
      title: "And just about anything else!"
    }
  ];

  const benefitsOfHomeEquityLineOfCredit = [
    {
      id: 1,
      title: "We pay closing costs for our existing WaFd home loan clients on lines of credit up to $250,000*"
    },
    {
      id: 2,
      title: "Discount of 0.25% on your interest rate when you make automatic payments from your WaFd checking account"
    },
    {
      id: 3,
      title: "Available for owner occupied, second homes, and investment properties"
    }
  ];

  const HeloanMobileTable = () => {
    return (
      <table class="table table-bordered d-md-none">
        <tbody>
          <tr id="table-heloan-row-01">
            <th className="w-50">Detail</th>
            <th className="py-2">
              <Link
                className="btn btn-primary w-100"
                to="/personal-banking/home-loans/heloan-home-equity-loan"
                id="table-heloan-home-equity-loan-link"
              >
                HELOAN
              </Link>
            </th>
          </tr>
          <tr id="table-heloan-row-02">
            <td>
              <strong>Uses your home's equity as collateral</strong>
            </td>
            <td>Yes</td>
          </tr>
          <tr id="table-heloan-row-03">
            <td>
              <strong>Has closing costs</strong>
            </td>
            <td>Yes</td>
          </tr>
          <tr id="table-heloan-row-04">
            <td>
              <strong>Potential tax deductions available</strong>
            </td>
            <td>Yes (check with a tax advisor)</td>
          </tr>
          <tr id="table-heloan-row-05">
            <td>
              <strong>How you can use it</strong>
            </td>
            <td>For just about anything</td>
          </tr>
          <tr id="table-heloan-row-06">
            <td>
              <strong>Interest rate type</strong>
            </td>
            <td>Fixed</td>
          </tr>
          <tr id="table-heloan-row-07">
            <td>
              <strong>Monthly Payment</strong>
            </td>
            <td>Set</td>
          </tr>
          <tr id="table-heloan-row-08">
            <td>
              <strong>Amount borrowed</strong>
            </td>
            <td>Receive a lump sum for the total amount you applied for</td>
          </tr>
          <tr id="table-heloan-row-09">
            <td>
              <strong>Loan changes</strong>
            </td>
            <td>None</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const HelocMobileTable = () => {
    return (
      <table class="table table-bordered d-md-none">
        <tbody>
          <tr id="table-heloc-row-01">
            <th className="w-50">Detail</th>
            <th className="py-2">
              <Link
                className="btn btn-primary w-100"
                to="/personal-banking/home-loans/heloc-home-equity-line-of-credit"
                id="table-heloc-home-equity-line-of-credit-link"
              >
                HELOC
              </Link>
            </th>
          </tr>
          <tr id="table-heloc-row-02">
            <td>
              <strong>Uses your home's equity as collateral</strong>
            </td>
            <td>Yes</td>
          </tr>
          <tr id="table-heloc-row-03">
            <td>
              <strong>Has closing costs</strong>
            </td>
            <td>Yes</td>
          </tr>
          <tr id="table-heloc-row-04">
            <td>
              <strong>Potential tax deductions available</strong>
            </td>
            <td>Yes (check with a tax advisor)</td>
          </tr>
          <tr id="table-heloc-row-05">
            <td>
              <strong>How you can use it</strong>
            </td>
            <td>For just about anything</td>
          </tr>
          <tr id="table-heloc-row-06">
            <td>
              <strong>Interest rate type</strong>
            </td>
            <td>Variable</td>
          </tr>
          <tr id="table-heloc-row-07">
            <td>
              <strong>Monthly Payment</strong>
            </td>
            <td>Changes, payments are interest only during the draw period</td>
          </tr>
          <tr id="table-heloc-row-08">
            <td>
              <strong>Amount borrowed</strong>
            </td>
            <td>
              Access to a line of credit for the total amount you applied for (only pay back what you actually borrow)
            </td>
          </tr>
          <tr id="table-heloc-row-09">
            <td>
              <strong>Loan changes</strong>
            </td>
            <td>
              After the draw period, your HELOC will change to an amortized loan with principal and interest, called the
              repayment period (15 years at WaFd Bank)
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const TableSection = () => {
    return (
      <section className="container">
        <HeloanMobileTable />
        <HelocMobileTable />
        <table className="table table-bordered d-none d-md-block">
          <tbody>
            <tr id="table-row-01">
              <th className="w-25">Detail</th>
              <th className="w-25 py-2">
                <Link
                  className="btn btn-primary w-100"
                  to="/personal-banking/home-loans/heloan-home-equity-loan"
                  id="table-heloan-home-equity-loan-link"
                >
                  HELOAN
                </Link>
              </th>
              <th className="w-25 py-2">
                <Link
                  className="btn btn-primary w-100"
                  to="/personal-banking/home-loans/heloc-home-equity-line-of-credit"
                  id="table-heloc-home-equity-line-of-credit-link"
                >
                  HELOC
                </Link>
              </th>
            </tr>
            <tr id="table-row-02">
              <td>
                <strong>Uses your home's equity as collateral</strong>
              </td>
              <td>Yes</td>
              <td>Yes</td>
            </tr>
            <tr id="table-row-03">
              <td>
                <strong>Has closing costs</strong>
              </td>
              <td>Yes</td>
              <td>Yes</td>
            </tr>
            <tr id="table-row-04">
              <td>
                <strong>Potential tax deductions available</strong>
              </td>
              <td>Yes (check with a tax advisor)</td>
              <td>Yes (check with a tax advisor)</td>
            </tr>
            <tr id="table-row-05">
              <td>
                <strong>How you can use it</strong>
              </td>
              <td>For just about anything</td>
              <td>For just about anything</td>
            </tr>
            <tr id="table-row-06">
              <td>
                <strong>Interest rate type</strong>
              </td>
              <td>Fixed</td>
              <td>Variable</td>
            </tr>
            <tr id="table-row-07">
              <td>
                <strong>Monthly Payment</strong>
              </td>
              <td>Set</td>
              <td>Changes, payments are interest only during the draw period</td>
            </tr>
            <tr id="table-row-08">
              <td>
                <strong>Amount borrowed</strong>
              </td>
              <td>Receive a lump sum for the total amount you applied for</td>
              <td>
                Access to a line of credit for the total amount you applied for (only pay back what you actually borrow)
              </td>
            </tr>
            <tr id="table-row-09">
              <td>
                <strong>Loan changes</strong>
              </td>
              <td>None</td>
              <td>
                After the draw period, your HELOC will change to an amortized loan with principal and interest, called
                the repayment period (15 years at WaFd Bank)
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    );
  };

  const helocVideoData = {
    vimeoId: "1014940338",
    videoTitle: "Save 1% on HELOC Rate (Home Equity Line of Credit)",
    class: "m-auto iframe w-100 border-radius-12"
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <LoanOfficerSearch isModalSearch={true} show={showLOModal} handleClose={handleCloseLOModal} />

      <section className="container">
        <h1>How to Pull Equity Out of Your Home Without Refinancing</h1>
        <h3 className="mb-0">
          You can access your home's equity through a few different options, such as refinancing or, if current interest
          rates are higher than the current rate on your mortgage, you can access your equity through a home equity line
          of credit (
          <Link id="heloc-link" to="/personal-banking/home-loans/heloc-home-equity-line-of-credit">
            HELOC
          </Link>
          ) or a home equity loan (
          <Link id="heloan-link" to="/personal-banking/home-loans/heloan-home-equity-loan">
            HELOAN
          </Link>
          ).
        </h3>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <h2 className="text-green-60 font-weight-semi-bold">What is Home Equity</h2>
              <p className="mb-md-0">
                Home equity is the part of your home's value that you own outright. It's the difference between the
                total amount your home is worth and what you owe on your home (your mortgage). It can be a valuable tool
                to help you build wealth over time as your home increases in value. The equity in your home can be used
                for just about anything, from home improvements or debt consolidation to education expenses and beyond.
                Whatever you plan to use it for, it's always a good idea to consult a financial advisor and think
                through your current and future financial situation and your goals.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <VimeoVideoFrame {...helocVideoData} />
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-12 col-md-6 order-1 order-md-0">
            <StaticImage
              src="../../../images/thumbnail-heloan-vs-heloc-111423.jpg"
              alt="Man sitting on kitchen floor determining which plumbing tool to use."
              placeholder="blurred"
              quality="100"
              className="mb-2 "
            />
          </div>
          <div className="col-12 col-md-6">
            <h2 className="text-green-60 font-weight-semi-bold">HELOC vs HELOAN</h2>
            <p>
              Both a{" "}
              <Link
                id="hvsh-heloc-home-equity-line-of-credit-link-1"
                to="/personal-banking/home-loans/heloc-home-equity-line-of-credit"
              >
                HELOC
              </Link>{" "}
              and a{" "}
              <Link id="hvsh-heloan-home-equity-loan-link-1" to="/personal-banking/home-loans/heloan-home-equity-loan">
                HELOAN
              </Link>{" "}
              are secured by the equity you've built in your home. You can also learn more in our article{" "}
              <Link
                id="hvsh-what-is-heloan-how-it-works-blog-link"
                to="/blog/home-ownership/what-is-heloan-how-it-works"
              >
                What is a Home Equity Loan (HELOAN) and How Does it Work
              </Link>
              . Either option can be far less expensive than a credit card and therefore a more affordable way to
              borrow. A HELOC is a line of credit available for you to borrow from and pay back as needed with a
              variable interest rate. With a HELOAN, you get one lump sum of money that is paid back over time with a
              fixed interest rate.
            </p>
            <p className="mb-md-0">
              WaFd Bank offers both a{" "}
              <Link
                id="hvsh-heloc-home-equity-line-of-credit-link-2"
                to="/personal-banking/home-loans/heloc-home-equity-line-of-credit"
              >
                HELOC
              </Link>{" "}
              and a{" "}
              <Link id="hvsh-heloan-home-equity-loan-link-2" to="/personal-banking/home-loans/heloan-home-equity-loan">
                HELOAN
              </Link>
              .
            </p>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <h2 className="text-green-60 font-weight-semi-bold">
                Benefits of a WaFd Home Equity Loan and Line of Credit
              </h2>
              <ul className={`fa-ul`}>
                {benefitsOfHomeEquityLineOfCredit.map((item, index) => (
                  <li key={item.id} className="text-green-90" id={item.id}>
                    <span className="fa-li" style={{ fontSize: "1.2em", lineHeight: "1.25em" }}>
                      <FontAwesomeIcon className="text-green-60 mr-2" icon={["fas", "check-circle"]} />
                    </span>
                    <span>{item.title}</span>
                  </li>
                ))}
              </ul>
              <div id="heloc-closing-cost-disclaimer" className="text-sm">
                * Closing costs for our existing WaFd home loan clients are paid only for primary or second/vacation
                homes, not on investment properties.
              </div>
            </div>
            <div className="col-12 col-md-6">
              <StaticImage
                src="../../../images/thumbnail-benefits-hloan-heloc-111423.jpg"
                alt="Mature couple in their living room hugging and holding mugs."
                placeholder="blurred"
                quality="100"
                className="mb-2 "
              />
            </div>
          </div>
        </div>
      </section>
      <section className="container pb-0">
        <div className="row">
          <div className="col-12 col-md-6 order-1 order-md-0">
            <StaticImage
              quality="100"
              placeholder="blurred"
              src="../../../images/thumbnail-heloc-promotion-072424.jpg"
              alt="A couple with their contractor reviewing construction progress."
            />
          </div>
          <div className="col-12 col-md-6">
            <h2 className="text-green-60 font-weight-semi-bold">
              Put Your Home's Equity to Work with 1% Off Your Rate on a HELOC for the first 6 Months
            </h2>
            <ul className={`fa-ul`}>
              {benefitsOfDiscountRateOnHELOC.map((item, index) => (
                <li key={item.id} className="text-green-90" id={"benefits-discount-rates-li-" + item.id}>
                  <span className="fa-li" style={{ fontSize: "1.2em", lineHeight: "1.25em" }}>
                    <Icon class="text-green-60 mr-2" lib="fas" name="check-circle" />
                  </span>
                  <span>{item.title}</span>
                </li>
              ))}
            </ul>
            <Button
              id="heloc-apply-online-today-modal-cta"
              type="button"
              class="btn-primary"
              containerClass="my-4"
              onClick={handleShowLOModal}
              text="Apply Online Today"
              showIcon={false}
            />
            <p id="heloc-rate-discount-disclaimer" className="text-sm">
              An introductory rate discount of 1% will be applied to the APR for the first six months at a fixed rate
              (payments are variable after that).
            </p>
            <p id="heloc-to-be-eligible-disclaimer" className="text-sm mb-0">
              To be eligible: Open or maintain a WaFd Bank personal checking account with direct deposit and get set up
              with WaFd's EZ Pay payment option from your WaFd personal checking account.
            </p>
          </div>
        </div>
      </section>
      <TableSection />
    </SecondaryLanding>
  );
};

export default UsingHomeEquityHelocVsHomeEquityLoan;
